export default [
    {
      "countryId": 151,
      "claveSat": "MEX",
      "countryName": "México"
    },
    {
      "countryId": 14,
      "claveSat": "ABW",
      "countryName": "Aruba"
    },
    {
      "countryId": 1,
      "claveSat": "AFG",
      "countryName": "Afganistán"
    },
    {
      "countryId": 6,
      "claveSat": "AGO",
      "countryName": "Angola"
    },
    {
      "countryId": 7,
      "claveSat": "AIA",
      "countryName": "Anguila"
    },
    {
      "countryId": 2,
      "claveSat": "ALA",
      "countryName": "Islas Åland"
    },
    {
      "countryId": 3,
      "claveSat": "ALB",
      "countryName": "Albania"
    },
    {
      "countryId": 5,
      "claveSat": "AND",
      "countryName": "Andorra"
    },
    {
      "countryId": 61,
      "claveSat": "ARE",
      "countryName": "Emiratos Árabes Unidos (Los)"
    },
    {
      "countryId": 12,
      "claveSat": "ARG",
      "countryName": "Argentina"
    },
    {
      "countryId": 13,
      "claveSat": "ARM",
      "countryName": "Armenia"
    },
    {
      "countryId": 195,
      "claveSat": "ASM",
      "countryName": "Samoa Americana"
    },
    {
      "countryId": 8,
      "claveSat": "ATA",
      "countryName": "Antártida"
    },
    {
      "countryId": 227,
      "claveSat": "ATF",
      "countryName": "Territorios Australes Franceses (los)"
    },
    {
      "countryId": 9,
      "claveSat": "ATG",
      "countryName": "Antigua y Barbuda"
    },
    {
      "countryId": 15,
      "claveSat": "AUS",
      "countryName": "Australia"
    },
    {
      "countryId": 16,
      "claveSat": "AUT",
      "countryName": "Austria"
    },
    {
      "countryId": 17,
      "claveSat": "AZE",
      "countryName": "Azerbaiyán"
    },
    {
      "countryId": 35,
      "claveSat": "BDI",
      "countryName": "Burundi"
    },
    {
      "countryId": 22,
      "claveSat": "BEL",
      "countryName": "Bélgica"
    },
    {
      "countryId": 24,
      "claveSat": "BEN",
      "countryName": "Benín"
    },
    {
      "countryId": 42,
      "claveSat": "BES",
      "countryName": "Bonaire, San Eustaquio y Saba"
    },
    {
      "countryId": 34,
      "claveSat": "BFA",
      "countryName": "Burkina Faso"
    },
    {
      "countryId": 19,
      "claveSat": "BGD",
      "countryName": "Bangladés"
    },
    {
      "countryId": 33,
      "claveSat": "BGR",
      "countryName": "Bulgaria"
    },
    {
      "countryId": 21,
      "claveSat": "BHR",
      "countryName": "Baréin"
    },
    {
      "countryId": 18,
      "claveSat": "BHS",
      "countryName": "Bahamas (las)"
    },
    {
      "countryId": 29,
      "claveSat": "BIH",
      "countryName": "Bosnia y Herzegovina"
    },
    {
      "countryId": 196,
      "claveSat": "BLM",
      "countryName": "San Bartolomé"
    },
    {
      "countryId": 26,
      "claveSat": "BLR",
      "countryName": "Bielorrusia"
    },
    {
      "countryId": 23,
      "claveSat": "BLZ",
      "countryName": "Belice"
    },
    {
      "countryId": 25,
      "claveSat": "BMU",
      "countryName": "Bermudas"
    },
    {
      "countryId": 28,
      "claveSat": "BOL",
      "countryName": "Bolivia, Estado Plurinacional de"
    },
    {
      "countryId": 31,
      "claveSat": "BRA",
      "countryName": "Brasil"
    },
    {
      "countryId": 20,
      "claveSat": "BRB",
      "countryName": "Barbados"
    },
    {
      "countryId": 32,
      "claveSat": "BRN",
      "countryName": "Brunéi Darussalam"
    },
    {
      "countryId": 36,
      "claveSat": "BTN",
      "countryName": "Bután"
    },
    {
      "countryId": 99,
      "claveSat": "BVT",
      "countryName": "Isla Bouvet"
    },
    {
      "countryId": 30,
      "claveSat": "BWA",
      "countryName": "Botsuana"
    },
    {
      "countryId": 183,
      "claveSat": "CAF",
      "countryName": "República Centroafricana (la)"
    },
    {
      "countryId": 40,
      "claveSat": "CAN",
      "countryName": "Canadá"
    },
    {
      "countryId": 105,
      "claveSat": "CCK",
      "countryName": "Islas Cocos (Keeling)"
    },
    {
      "countryId": 219,
      "claveSat": "CHE",
      "countryName": "Suiza"
    },
    {
      "countryId": 44,
      "claveSat": "CHL",
      "countryName": "Chile"
    },
    {
      "countryId": 45,
      "claveSat": "CHN",
      "countryName": "China"
    },
    {
      "countryId": 51,
      "claveSat": "CIV",
      "countryName": "Côte d Ivoire"
    },
    {
      "countryId": 39,
      "claveSat": "CMR",
      "countryName": "Camerún"
    },
    {
      "countryId": 187,
      "claveSat": "COD",
      "countryName": "Congo (la República Democrática del)"
    },
    {
      "countryId": 186,
      "claveSat": "COG",
      "countryName": "Congo"
    },
    {
      "countryId": 106,
      "claveSat": "COK",
      "countryName": "Islas Cook (las)"
    },
    {
      "countryId": 47,
      "claveSat": "COL",
      "countryName": "Colombia"
    },
    {
      "countryId": 48,
      "claveSat": "COM",
      "countryName": "Comoras"
    },
    {
      "countryId": 37,
      "claveSat": "CPV",
      "countryName": "Cabo Verde"
    },
    {
      "countryId": 52,
      "claveSat": "CRI",
      "countryName": "Costa Rica"
    },
    {
      "countryId": 54,
      "claveSat": "CUB",
      "countryName": "Cuba"
    },
    {
      "countryId": 55,
      "claveSat": "CUW",
      "countryName": "Curaçao"
    },
    {
      "countryId": 101,
      "claveSat": "CXR",
      "countryName": "Isla de Navidad"
    },
    {
      "countryId": 104,
      "claveSat": "CYM",
      "countryName": "Islas Caimán (las)"
    },
    {
      "countryId": 46,
      "claveSat": "CYP",
      "countryName": "Chipre"
    },
    {
      "countryId": 184,
      "claveSat": "CZE",
      "countryName": "República Checa (la)"
    },
    {
      "countryId": 4,
      "claveSat": "DEU",
      "countryName": "Alemania"
    },
    {
      "countryId": 247,
      "claveSat": "DJI",
      "countryName": "Yibuti"
    },
    {
      "countryId": 57,
      "claveSat": "DMA",
      "countryName": "Dominica"
    },
    {
      "countryId": 56,
      "claveSat": "DNK",
      "countryName": "Dinamarca"
    },
    {
      "countryId": 188,
      "claveSat": "DOM",
      "countryName": "República Dominicana (la)"
    },
    {
      "countryId": 11,
      "claveSat": "DZA",
      "countryName": "Argelia"
    },
    {
      "countryId": 58,
      "claveSat": "ECU",
      "countryName": "Ecuador"
    },
    {
      "countryId": 59,
      "claveSat": "EGY",
      "countryName": "Egipto"
    },
    {
      "countryId": 62,
      "claveSat": "ERI",
      "countryName": "Eritrea"
    },
    {
      "countryId": 193,
      "claveSat": "ESH",
      "countryName": "Sahara Occidental"
    },
    {
      "countryId": 65,
      "claveSat": "ESP",
      "countryName": "España"
    },
    {
      "countryId": 67,
      "claveSat": "EST",
      "countryName": "Estonia"
    },
    {
      "countryId": 68,
      "claveSat": "ETH",
      "countryName": "Etiopía"
    },
    {
      "countryId": 70,
      "claveSat": "FIN",
      "countryName": "Finlandia"
    },
    {
      "countryId": 71,
      "claveSat": "FJI",
      "countryName": "Fiyi"
    },
    {
      "countryId": 110,
      "claveSat": "FLK",
      "countryName": "Islas Malvinas [Falkland] (las)"
    },
    {
      "countryId": 72,
      "claveSat": "FRA",
      "countryName": "Francia"
    },
    {
      "countryId": 107,
      "claveSat": "FRO",
      "countryName": "Islas Feroe (las)"
    },
    {
      "countryId": 152,
      "claveSat": "FSM",
      "countryName": "Micronesia (los Estados Federados de)"
    },
    {
      "countryId": 73,
      "claveSat": "GAB",
      "countryName": "Gabón"
    },
    {
      "countryId": 182,
      "claveSat": "GBR",
      "countryName": "Reino Unido (el)"
    },
    {
      "countryId": 75,
      "claveSat": "GEO",
      "countryName": "Georgia"
    },
    {
      "countryId": 85,
      "claveSat": "GGY",
      "countryName": "Guernsey"
    },
    {
      "countryId": 76,
      "claveSat": "GHA",
      "countryName": "Ghana"
    },
    {
      "countryId": 77,
      "claveSat": "GIB",
      "countryName": "Gibraltar"
    },
    {
      "countryId": 86,
      "claveSat": "GIN",
      "countryName": "Guinea"
    },
    {
      "countryId": 81,
      "claveSat": "GLP",
      "countryName": "Guadalupe"
    },
    {
      "countryId": 74,
      "claveSat": "GMB",
      "countryName": "Gambia (La)"
    },
    {
      "countryId": 87,
      "claveSat": "GNB",
      "countryName": "Guinea-Bisáu"
    },
    {
      "countryId": 88,
      "claveSat": "GNQ",
      "countryName": "Guinea Ecuatorial"
    },
    {
      "countryId": 79,
      "claveSat": "GRC",
      "countryName": "Grecia"
    },
    {
      "countryId": 78,
      "claveSat": "GRD",
      "countryName": "Granada"
    },
    {
      "countryId": 80,
      "claveSat": "GRL",
      "countryName": "Groenlandia"
    },
    {
      "countryId": 83,
      "claveSat": "GTM",
      "countryName": "Guatemala"
    },
    {
      "countryId": 84,
      "claveSat": "GUF",
      "countryName": "Guayana Francesa"
    },
    {
      "countryId": 82,
      "claveSat": "GUM",
      "countryName": "Guam"
    },
    {
      "countryId": 89,
      "claveSat": "GUY",
      "countryName": "Guyana"
    },
    {
      "countryId": 92,
      "claveSat": "HKG",
      "countryName": "Hong Kong"
    },
    {
      "countryId": 109,
      "claveSat": "HMD",
      "countryName": "Isla Heard e Islas McDonald"
    },
    {
      "countryId": 91,
      "claveSat": "HND",
      "countryName": "Honduras"
    },
    {
      "countryId": 53,
      "claveSat": "HRV",
      "countryName": "Croacia"
    },
    {
      "countryId": 90,
      "claveSat": "HTI",
      "countryName": "Haití"
    },
    {
      "countryId": 93,
      "claveSat": "HUN",
      "countryName": "Hungría"
    },
    {
      "countryId": 95,
      "claveSat": "IDN",
      "countryName": "Indonesia"
    },
    {
      "countryId": 100,
      "claveSat": "IMN",
      "countryName": "Isla de Man"
    },
    {
      "countryId": 94,
      "claveSat": "IND",
      "countryName": "India"
    },
    {
      "countryId": 226,
      "claveSat": "IOT",
      "countryName": "Territorio Británico del Océano Índico (el)"
    },
    {
      "countryId": 98,
      "claveSat": "IRL",
      "countryName": "Irlanda"
    },
    {
      "countryId": 97,
      "claveSat": "IRN",
      "countryName": "Irán (la República Islámica de)"
    },
    {
      "countryId": 96,
      "claveSat": "IRQ",
      "countryName": "Irak"
    },
    {
      "countryId": 103,
      "claveSat": "ISL",
      "countryName": "Islandia"
    },
    {
      "countryId": 119,
      "claveSat": "ISR",
      "countryName": "Israel"
    },
    {
      "countryId": 120,
      "claveSat": "ITA",
      "countryName": "Italia"
    },
    {
      "countryId": 121,
      "claveSat": "JAM",
      "countryName": "Jamaica"
    },
    {
      "countryId": 123,
      "claveSat": "JEY",
      "countryName": "Jersey"
    },
    {
      "countryId": 124,
      "claveSat": "JOR",
      "countryName": "Jordania"
    },
    {
      "countryId": 122,
      "claveSat": "JPN",
      "countryName": "Japón"
    },
    {
      "countryId": 125,
      "claveSat": "KAZ",
      "countryName": "Kazajistán"
    },
    {
      "countryId": 126,
      "claveSat": "KEN",
      "countryName": "Kenia"
    },
    {
      "countryId": 127,
      "claveSat": "KGZ",
      "countryName": "Kirguistán"
    },
    {
      "countryId": 38,
      "claveSat": "KHM",
      "countryName": "Camboya"
    },
    {
      "countryId": 128,
      "claveSat": "KIR",
      "countryName": "Kiribati"
    },
    {
      "countryId": 197,
      "claveSat": "KNA",
      "countryName": "San Cristóbal y Nieves"
    },
    {
      "countryId": 50,
      "claveSat": "KOR",
      "countryName": "Corea (la República de)"
    },
    {
      "countryId": 129,
      "claveSat": "KWT",
      "countryName": "Kuwait"
    },
    {
      "countryId": 130,
      "claveSat": "LAO",
      "countryName": "Lao, (la) República Democrática Popular"
    },
    {
      "countryId": 133,
      "claveSat": "LBN",
      "countryName": "Líbano"
    },
    {
      "countryId": 134,
      "claveSat": "LBR",
      "countryName": "Liberia"
    },
    {
      "countryId": 135,
      "claveSat": "LBY",
      "countryName": "Libia"
    },
    {
      "countryId": 203,
      "claveSat": "LCA",
      "countryName": "Santa Lucía"
    },
    {
      "countryId": 136,
      "claveSat": "LIE",
      "countryName": "Liechtenstein"
    },
    {
      "countryId": 213,
      "claveSat": "LKA",
      "countryName": "Sri Lanka"
    },
    {
      "countryId": 131,
      "claveSat": "LSO",
      "countryName": "Lesoto"
    },
    {
      "countryId": 137,
      "claveSat": "LTU",
      "countryName": "Lituania"
    },
    {
      "countryId": 138,
      "claveSat": "LUX",
      "countryName": "Luxemburgo"
    },
    {
      "countryId": 132,
      "claveSat": "LVA",
      "countryName": "Letonia"
    },
    {
      "countryId": 139,
      "claveSat": "MAC",
      "countryName": "Macao"
    },
    {
      "countryId": 199,
      "claveSat": "MAF",
      "countryName": "San Martín (parte francesa)"
    },
    {
      "countryId": 146,
      "claveSat": "MAR",
      "countryName": "Marruecos"
    },
    {
      "countryId": 154,
      "claveSat": "MCO",
      "countryName": "Mónaco"
    },
    {
      "countryId": 153,
      "claveSat": "MDA",
      "countryName": "Moldavia (la República de)"
    },
    {
      "countryId": 140,
      "claveSat": "MDG",
      "countryName": "Madagascar"
    },
    {
      "countryId": 143,
      "claveSat": "MDV",
      "countryName": "Maldivas"
    },
    {
      "countryId": 112,
      "claveSat": "MHL",
      "countryName": "Islas Marshall (las)"
    },
    {
      "countryId": 185,
      "claveSat": "MKD",
      "countryName": "Macedonia (la antigua República Yugoslava de)"
    },
    {
      "countryId": 144,
      "claveSat": "MLI",
      "countryName": "Malí"
    },
    {
      "countryId": 145,
      "claveSat": "MLT",
      "countryName": "Malta"
    },
    {
      "countryId": 27,
      "claveSat": "MMR",
      "countryName": "Myanmar"
    },
    {
      "countryId": 156,
      "claveSat": "MNE",
      "countryName": "Montenegro"
    },
    {
      "countryId": 155,
      "claveSat": "MNG",
      "countryName": "Mongolia"
    },
    {
      "countryId": 111,
      "claveSat": "MNP",
      "countryName": "Islas Marianas del Norte (las)"
    },
    {
      "countryId": 158,
      "claveSat": "MOZ",
      "countryName": "Mozambique"
    },
    {
      "countryId": 149,
      "claveSat": "MRT",
      "countryName": "Mauritania"
    },
    {
      "countryId": 157,
      "claveSat": "MSR",
      "countryName": "Montserrat"
    },
    {
      "countryId": 147,
      "claveSat": "MTQ",
      "countryName": "Martinica"
    },
    {
      "countryId": 148,
      "claveSat": "MUS",
      "countryName": "Mauricio"
    },
    {
      "countryId": 142,
      "claveSat": "MWI",
      "countryName": "Malaui"
    },
    {
      "countryId": 141,
      "claveSat": "MYS",
      "countryName": "Malasia"
    },
    {
      "countryId": 150,
      "claveSat": "MYT",
      "countryName": "Mayotte"
    },
    {
      "countryId": 159,
      "claveSat": "NAM",
      "countryName": "Namibia"
    },
    {
      "countryId": 167,
      "claveSat": "NCL",
      "countryName": "Nueva Caledonia"
    },
    {
      "countryId": 163,
      "claveSat": "NER",
      "countryName": "Níger (el)"
    },
    {
      "countryId": 102,
      "claveSat": "NFK",
      "countryName": "Isla Norfolk"
    },
    {
      "countryId": 164,
      "claveSat": "NGA",
      "countryName": "Nigeria"
    },
    {
      "countryId": 162,
      "claveSat": "NIC",
      "countryName": "Nicaragua"
    },
    {
      "countryId": 165,
      "claveSat": "NIU",
      "countryName": "Niue"
    },
    {
      "countryId": 170,
      "claveSat": "NLD",
      "countryName": "Países Bajos (los)"
    },
    {
      "countryId": 166,
      "claveSat": "NOR",
      "countryName": "Noruega"
    },
    {
      "countryId": 161,
      "claveSat": "NPL",
      "countryName": "Nepal"
    },
    {
      "countryId": 160,
      "claveSat": "NRU",
      "countryName": "Nauru"
    },
    {
      "countryId": 168,
      "claveSat": "NZL",
      "countryName": "Nueva Zelanda"
    },
    {
      "countryId": 169,
      "claveSat": "OMN",
      "countryName": "Omán"
    },
    {
      "countryId": 171,
      "claveSat": "PAK",
      "countryName": "Pakistán"
    },
    {
      "countryId": 174,
      "claveSat": "PAN",
      "countryName": "Panamá"
    },
    {
      "countryId": 113,
      "claveSat": "PCN",
      "countryName": "Pitcairn"
    },
    {
      "countryId": 177,
      "claveSat": "PER",
      "countryName": "Perú"
    },
    {
      "countryId": 69,
      "claveSat": "PHL",
      "countryName": "Filipinas (las)"
    },
    {
      "countryId": 172,
      "claveSat": "PLW",
      "countryName": "Palaos"
    },
    {
      "countryId": 175,
      "claveSat": "PNG",
      "countryName": "Papúa Nueva Guinea"
    },
    {
      "countryId": 179,
      "claveSat": "POL",
      "countryName": "Polonia"
    },
    {
      "countryId": 181,
      "claveSat": "PRI",
      "countryName": "Puerto Rico"
    },
    {
      "countryId": 49,
      "claveSat": "PRK",
      "countryName": "Corea (la República Democrática Popular de)"
    },
    {
      "countryId": 180,
      "claveSat": "PRT",
      "countryName": "Portugal"
    },
    {
      "countryId": 176,
      "claveSat": "PRY",
      "countryName": "Paraguay"
    },
    {
      "countryId": 173,
      "claveSat": "PSE",
      "countryName": "Palestina, Estado de"
    },
    {
      "countryId": 178,
      "claveSat": "PYF",
      "countryName": "Polinesia Francesa"
    },
    {
      "countryId": 41,
      "claveSat": "QAT",
      "countryName": "Catar"
    },
    {
      "countryId": 189,
      "claveSat": "REU",
      "countryName": "Reunión"
    },
    {
      "countryId": 191,
      "claveSat": "ROU",
      "countryName": "Rumania"
    },
    {
      "countryId": 192,
      "claveSat": "RUS",
      "countryName": "Rusia, (la) Federación de"
    },
    {
      "countryId": 190,
      "claveSat": "RWA",
      "countryName": "Ruanda"
    },
    {
      "countryId": 10,
      "claveSat": "SAU",
      "countryName": "Arabia Saudita"
    },
    {
      "countryId": 216,
      "claveSat": "SDN",
      "countryName": "Sudán (el)"
    },
    {
      "countryId": 205,
      "claveSat": "SEN",
      "countryName": "Senegal"
    },
    {
      "countryId": 209,
      "claveSat": "SGP",
      "countryName": "Singapur"
    },
    {
      "countryId": 108,
      "claveSat": "SGS",
      "countryName": "Georgia del sur y las islas sandwich del sur"
    },
    {
      "countryId": 202,
      "claveSat": "SHN",
      "countryName": "Santa Helena, Ascensión y Tristán de Acuña"
    },
    {
      "countryId": 221,
      "claveSat": "SJM",
      "countryName": "Svalbard y Jan Mayen"
    },
    {
      "countryId": 114,
      "claveSat": "SLB",
      "countryName": "Islas Salomón (las)"
    },
    {
      "countryId": 208,
      "claveSat": "SLE",
      "countryName": "Sierra leona"
    },
    {
      "countryId": 60,
      "claveSat": "SLV",
      "countryName": "El Salvador"
    },
    {
      "countryId": 198,
      "claveSat": "SMR",
      "countryName": "San Marino"
    },
    {
      "countryId": 212,
      "claveSat": "SOM",
      "countryName": "Somalia"
    },
    {
      "countryId": 200,
      "claveSat": "SPM",
      "countryName": "San Pedro y Miquelón"
    },
    {
      "countryId": 206,
      "claveSat": "SRB",
      "countryName": "Serbia"
    },
    {
      "countryId": 217,
      "claveSat": "SSD",
      "countryName": "Sudán del Sur"
    },
    {
      "countryId": 204,
      "claveSat": "STP",
      "countryName": "Santo Tomé y Príncipe"
    },
    {
      "countryId": 220,
      "claveSat": "SUR",
      "countryName": "Surinam"
    },
    {
      "countryId": 63,
      "claveSat": "SVK",
      "countryName": "Eslovaquia"
    },
    {
      "countryId": 64,
      "claveSat": "SVN",
      "countryName": "Eslovenia"
    },
    {
      "countryId": 218,
      "claveSat": "SWE",
      "countryName": "Suecia"
    },
    {
      "countryId": 214,
      "claveSat": "SWZ",
      "countryName": "Suazilandia"
    },
    {
      "countryId": 210,
      "claveSat": "SXM",
      "countryName": "Sint Maarten (parte holandesa)"
    },
    {
      "countryId": 207,
      "claveSat": "SYC",
      "countryName": "Seychelles"
    },
    {
      "countryId": 211,
      "claveSat": "SYR",
      "countryName": "Siria, (la) República Árabe"
    },
    {
      "countryId": 115,
      "claveSat": "TCA",
      "countryName": "Islas Turcas y Caicos (las)"
    },
    {
      "countryId": 43,
      "claveSat": "TCD",
      "countryName": "Chad"
    },
    {
      "countryId": 229,
      "claveSat": "TGO",
      "countryName": "Togo"
    },
    {
      "countryId": 222,
      "claveSat": "THA",
      "countryName": "Tailandia"
    },
    {
      "countryId": 225,
      "claveSat": "TJK",
      "countryName": "Tayikistán"
    },
    {
      "countryId": 230,
      "claveSat": "TKL",
      "countryName": "Tokelau"
    },
    {
      "countryId": 234,
      "claveSat": "TKM",
      "countryName": "Turkmenistán"
    },
    {
      "countryId": 228,
      "claveSat": "TLS",
      "countryName": "Timor-Leste"
    },
    {
      "countryId": 231,
      "claveSat": "TON",
      "countryName": "Tonga"
    },
    {
      "countryId": 232,
      "claveSat": "TTO",
      "countryName": "Trinidad y Tobago"
    },
    {
      "countryId": 233,
      "claveSat": "TUN",
      "countryName": "Túnez"
    },
    {
      "countryId": 235,
      "claveSat": "TUR",
      "countryName": "Turquía"
    },
    {
      "countryId": 236,
      "claveSat": "TUV",
      "countryName": "Tuvalu"
    },
    {
      "countryId": 223,
      "claveSat": "TWN",
      "countryName": "Taiwán (Provincia de China)"
    },
    {
      "countryId": 224,
      "claveSat": "TZA",
      "countryName": "Tanzania, República Unida de"
    },
    {
      "countryId": 238,
      "claveSat": "UGA",
      "countryName": "Uganda"
    },
    {
      "countryId": 237,
      "claveSat": "UKR",
      "countryName": "Ucrania"
    },
    {
      "countryId": 116,
      "claveSat": "UMI",
      "countryName": "Islas de Ultramar Menores de Estados Unidos (las)"
    },
    {
      "countryId": 239,
      "claveSat": "URY",
      "countryName": "Uruguay"
    },
    {
      "countryId": 66,
      "claveSat": "USA",
      "countryName": "Estados Unidos (los)"
    },
    {
      "countryId": 240,
      "claveSat": "UZB",
      "countryName": "Uzbekistán"
    },
    {
      "countryId": 242,
      "claveSat": "VAT",
      "countryName": "Santa Sede[Estado de la Ciudad del Vaticano] (la)"
    },
    {
      "countryId": 201,
      "claveSat": "VCT",
      "countryName": "San Vicente y las Granadinas"
    },
    {
      "countryId": 243,
      "claveSat": "VEN",
      "countryName": "Venezuela, República Bolivariana de"
    },
    {
      "countryId": 117,
      "claveSat": "VGB",
      "countryName": "Islas Vírgenes (Británicas)"
    },
    {
      "countryId": 118,
      "claveSat": "VIR",
      "countryName": "Islas Vírgenes (EE.UU.)"
    },
    {
      "countryId": 244,
      "claveSat": "VNM",
      "countryName": "Viet Nam"
    },
    {
      "countryId": 241,
      "claveSat": "VUT",
      "countryName": "Vanuatu"
    },
    {
      "countryId": 245,
      "claveSat": "WLF",
      "countryName": "Wallis y Futuna"
    },
    {
      "countryId": 194,
      "claveSat": "WSM",
      "countryName": "Samoa"
    },
    {
      "countryId": 0,
      "claveSat": "XXX",
      "countryName": "NO ESPECIFICADO"
    },
    {
      "countryId": 246,
      "claveSat": "YEM",
      "countryName": "Yemen"
    },
    {
      "countryId": 215,
      "claveSat": "ZAF",
      "countryName": "Sudáfrica"
    },
    {
      "countryId": 248,
      "claveSat": "ZMB",
      "countryName": "Zambia"
    },
    {
      "countryId": 249,
      "claveSat": "ZWE",
      "countryName": "Zimbabue"
    },
    {
      "countryId": 250,
      "claveSat": "ZZZ",
      "countryName": "Países no declarados"
    }
  ]