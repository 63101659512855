export default [
    {
      "value": "AGUASCALIENTES",
      "label":"AGUASCALIENTES"
    }, {
      "value": "BAJA CALIFORNIA",
      "label":"BAJA CALIFORNIA"
    }, {
      "value": "BAJA CALIFORNIA SUR",
      "label":"BAJA CALIFORNIA SUR"
    }, {
      "value": "CAMPECHE",
      "label":"CAMPECHE"
    }, {
      "value": "CHIAPAS",
      "label":"CHIAPAS"
    }, {
      "value": "CHIHUAHUA",
      "label":"CHIHUAHUA"
    }, {
      "value": "CIUDAD DE MEXICO",
      "label":"CIUDAD DE MEXICO"
    }, {
      "value": "COAHUILA DE ZARAGOZA",
      "label":"COAHUILA DE ZARAGOZA"
    }, {
      "value": "COLIMA",
      "label":"COLIMA"
    }, {
      "value": "DURANGO",
      "label":"DURANGO"
    }, {
      "value": "ESTADO DE MEXICO",
      "label":"ESTADO DE MEXICO"
    }, {
      "value": "GUANAJUATO",
      "label":"GUANAJUATO"
    }, {
      "value": "GUERRERO",
      "label":"GUERRERO"
    }, {
      "value": "HIDALGO",
      "label":"HIDALGO"
    }, {
      "value": "JALISCO",
      "label":"JALISCO"
    }, {
      "value": "MICHOACAN",
      "label":"MICHOACAN"
    }, {
      "value": "MORELOS",
      "label":"MORELOS"
    }, {
      "value": "NAYARIT",
      "label":"NAYARIT"
    }, {
      "value": "NUEVO LEON",
      "label":"NUEVO LEON"
    }, {
      "value": "OAXACA",
      "label":"OAXACA"
    }, {
      "value": "PUEBLA",
      "label":"PUEBLA"
    }, {
      "value": "QUERETARO",
      "label":"QUERETARO"
    }, {
      "value": "QUINTANA ROO",
      "label":"QUINTANA ROO"
    }, {
      "value": "SAN LUIS POTOSI",
      "label":"SAN LUIS POTOSI"
    }, {
      "value": "SINALOA",
      "label":"SINALOA"
    }, {
      "value": "SONORA",
      "label":"SONORA"
    }, {
      "value": "TABASCO",
      "label":"TABASCO"
    }, {
      "value": "TAMAULIPAS",
      "label":"TAMAULIPAS"
    }, {
      "value": "TLAXCALA",
      "label":"TLAXCALA"
    }, {
      "value": "VERACRUZ",
      "label":"VERACRUZ"
    }, {
      "value": "YUCATAN",
      "label":"YUCATAN"
    }, {
      "value": "ZACATECAS",
      "label":"ZACATECAS"
    }
  ]