export default [
  {
    value: "605",
    label: "Sueldos y Salarios e Ingresos Asimilados a Salarios",
  },
  {
    value: "606",
    label: "Arrendamiento",
  },
  {
    value: "607",
    label: "Régimen de Enajenación o Adquisición de Bienes",
  },
  {
    value: "608",
    label: "Demás ingresos",
  },
  {
    value: "610",
    label:
      "Residentes en el Extranjero sin Establecimiento Permanente en México",
  },
  {
    value: "611",
    label: "Ingresos por Dividendos (socios y accionistas)",
  },
  {
    value: "612",
    label: "Personas Físicas con Actividades Empresariales y Profesionales",
  },
  {
    value: "614",
    label: "Ingresos por intereses",
  },
  {
    value: "615",
    label: "Régimen de los ingresos por obtención de premios",
  },
  {
    value: "616",
    label: "Sin obligaciones fiscales",
  },
  {
    value: "621",
    label: "Incorporación Fiscal",
  },
  {
    value: "625",
    label:
      "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
  },
  {
    value: "626",
    label: "Régimen Simplificado de Confianza",
  }
]
