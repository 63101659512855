export default [
  {
    value: "G01",
    label: "Adquisición de mercancías.",
  },
  {
    value: "G02",
    label: "Devoluciones, descuentos o bonificaciones.",
  },
  {
    value: "G03",
    label: "Gastos en general.",
  },
  {
    value: "I01",
    label: "Construcciones.",
  },
  {
    value: "I02",
    label: "Mobiliario y equipo de oficina por inversiones.",
  },
  {
    value: "I03",
    label: "Equipo de transporte.",
  },
  {
    value: "I04",
    label: "Equipo de computo y accesorios.",
  },
  {
    value: "I05",
    label: "Dados, troqueles, moldes, matrices y herramental.",
  },
  {
    value: "I06",
    label: "Comunicaciones telefónicas.",
  },
  {
    value: "I07",
    label: "Comunicaciones satelitales.",
  },
  {
    value: "I08",
    label: "Otra maquinaria y equipo.",
  },
  {
    value: "D01",
    label: "Honorarios médicos, dentales y gastos hospitalarios.",
  },
  {
    value: "D02",
    label: "Gastos médicos por incapacidad o discapacidad.",
  },
  {
    value: "D03",
    label: "Gastos funerales.",
  },
  {
    value: "D04",
    label: "Donativos.",
  },
  {
    value: "D05",
    label:
      "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).",
  },
  {
    value: "D06",
    label: "Aportaciones voluntarias al SAR.",
  },
  {
    value: "D07",
    label: "Primas por seguros de gastos médicos.",
  },
  {
    value: "D08",
    label: "Gastos de transportación escolar obligatoria.",
  },
  {
    value: "D09",
    label:
      "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.",
  },
  {
    value: "D10",
    label: "Pagos por servicios educativos (colegiaturas).",
  },
  {
    value: "S01",
    label: "Sin efectos fiscales.  ",
  },
  {
    value: "CP01",
    label: "Pagos",
  },
  {
    value: "CN01",
    label: "Nómina",
  },
];
